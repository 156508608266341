import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/jamesabaja/MedGrocer/maersk-pbm/src/components/Layout/Layout.js";
import { graphql } from "gatsby";
import Completed from '../../components/Completed/Completed';
export const query = graphql`
  query {
    completed: file(relativePath: { eq: "complete__wallet.png" })
    {
      childImageSharp {
        fluid(
          maxWidth: 512
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
   }`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <Completed icon={props.data.completed.childImageSharp.fluid} mdxType="Completed">
      <p className="pb-1">
A MedGrocer representative will contact you through email to confirm your claim. If you don't receive an email within the next 1-2 business days, please contact us by replying to your confirmation email.
      </p>
      <section className="notification is-light p-1 mt-1">
 <p className="is-size-6 has-text-left mb-1">Reminder:</p>
  <ul className="ml-2 has-text-left">
 <li>Please ensure that you keep your original receipts (hard copies), and put these in an envelope. At the back of the envelope, please indicate your request reference number, your APM ID, and Date of crediting as this will be required for submission via Medical Allowance Reimbursement drop box @7/F Payroll.</li>
 <li>The deadline for submission of physical documents is to be announced. Please wait for further announcements regarding the final deadline. </li>
 <li> In the incident of failure to submit all original receipts (hard copies) on the announced deadline, the amount credited will be recovered by the succeeding payroll.</li>
 <li>Please note that the final amount credited may change upon receipt of physical documents. If any discrepancies and issues are found upon collection of physical documents, the amount credited will be recovered by the succeeding payroll.</li>
  </ul>
      </section>
    </Completed>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      